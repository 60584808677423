<template>
    <div class="modal secondary history_details" v-if="modelValue">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1 class="m-0">{{ habitTracking.recurring_type == 2 ? 'Day' : 'Week' }} {{ week + 1 }}</h1>
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal_body">
                <!-- <div class="activity_wpr">
                    <div class="activity_details mb-2">
                        <h4 class="flex space-between">{{ weekHabit.description }} <i class="trophy fas fa-trophy mr-1 ml-1" v-if="streak.goalAchived && streak.goalAchived[week] == 1"></i></h4>
                        <div class="details">
                            <div class="days_activity">
                                <ul class="days" v-if="habitTracking.recurring_type == 2">
                                    <li :class="{ active: parseInt(weekAnswer) === 1 }" title="Yes">Y</li>
                                    <li :class="{ active: parseInt(weekAnswer) === 0 }" title="No">N</li>
                                </ul>
                                <ul class="days" v-if="habitTracking.recurring_type == 3">
                                    <li :class="{ active: streak.responses[week].includes(wd.value + 1) }" :title="wd.title" v-for="(wd, w) of weekdays" :key="w">{{ wd.label }}</li>
                                </ul>
                                <h5 v-if="habitTracking.recurring_type == 3 && habitTracking.is_cumulative">
                                    {{ streak.responses && streak.responses[week] && streak.responses[week].length ? (((streak.responses[week].length * 100) / streak.goal) > 100 ? 100 : ((streak.responses[week].length * 100) / streak.goal).toFixed()) : 0 }}%
                                </h5>
                            </div>
                            <span>Requirements:</span>
                            <p>{{ weekHabit.requirements }}</p>
                            <ul>
                                <li class="setting_wpr">
                                    <span>Performed:</span>
                                    <div class="perform_info">
                                        <label>{{ weekAnswer || (streak.responses[week].filter(w => w > 0)).length ? 'Yes' : 'No'}}</label>
                                    </div>
                                </li>
                                <li v-if="(weekAnswer || (streak.responses[week].filter(w => w > 0)).length) && weekHabit.submit_proof">
                                    <span>Proof: {{ streak.proofs[week] ? '' : 'NA' }} </span>
                                    <img v-if="streak.proofs[week]" :src="streak.proofs[week]" alt="" class="mb-2">
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> -->
                <div class="task_details">
                    <div class="task_info mb-2">
                        <h4><label>1</label>{{ weekHabit.description }}</h4>
                        <div class="details">
                            <p>{{ weekHabit.requirements ? weekHabit.requirements : 'No requirement available'}}</p>
                            <div class="perform_activity" v-if="habitTracking.recurring_type == 2">
                                <div class="activity_wpr">
                                    <div class="grp_wpr">
                                        <label class="yes" :class="{ active: parseInt(weekAnswer) === 1 }">
                                            <div class="box_item"><i class="fas fa-thumbs-up mr-2"></i>Yes</div>
                                        </label>
                                        <label class="no" :class="{ active: parseInt(weekAnswer) === 0 }">
                                            <div class="box_item"><i class="fas fa-thumbs-down mr-2"></i>No</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="perform_activity" v-if="habitTracking.recurring_type == 3">
                                <div class="success_goal"><i class="fas fa-trophy"></i>{{ `Goal is ${ weekHabit.success_goal > 1 ? weekHabit.success_goal+' days' : weekHabit.success_goal+' day' }` }} per week</div>
                                <div class="days_wpr">
                                    <ul class="days">
                                        <li v-for="(wd, w) of weekdays" :key="w">
                                            <label>
                                                <div class="item_wpr">
                                                    <span class="weekdays">{{ wd.label.slice(0, 3) }}</span>
                                                    <span class="tick_box" :class="{ active: streak.responses[week].includes(wd.value + 1) }"></span>
                                                </div>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div :class="habitTracking.recurring_type == 3 ? 'activity_wpr_center' : 'activity_wpr'">
                                <span>Performed habit?</span>
                                <div class="grp_wpr">
                                    <label class="active" :class="weekAnswer >= 0 || (streak.responses[week].filter(w => w > 0)).length ? 'yes' : 'no'">
                                        <div class="box_item"><i :class="`fas fa-thumbs-${weekAnswer >= 0 || (streak.responses[week].filter(w => w > 0)).length ? 'up' : 'down'} mr-2`"></i>{{ weekAnswer >= 0 || (streak.responses[week].filter(w => w > 0)).length ? 'Yes' : 'No'}}</div>
                                    </label>
                                </div>
                            </div>
                            <div class="upload_image" v-show="(weekAnswer >= 0 || (streak.responses[week].filter(w => w > 0)).length) && weekHabit.submit_proof">
                                <img v-if="streak.proofs[week]" :src="streak.proofs[week]" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Habit Week Dialog',

        data () {
            return {
                weekdays: [
                    { title: 'Monday', label: 'M', value: 1 },
                    { title: 'Tuesday', label: 'T', value: 2 },
                    { title: 'Wednesday', label: 'W', value: 3 },
                    { title: 'Thursday', label: 'T', value: 4 },
                    { title: 'Friday', label: 'F', value: 5 },
                    { title: 'Saturday', label: 'S', value: 6 },
                    { title: 'Sunday', label: 'S', value: 0 },
                ],
            };
        },

        props: {
            modelValue: Boolean,
            habitTracking: Object,
            week: [Number, String],
            weekHabit: Object,
            weekAnswer: [Number, String],
            streak: Object,
        },

        emits: ['update:modelValue'],

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },
        },
    }
</script>

<style scoped>
    .activity_details .details ul li .perform_info label:not(.checkbox) {
        background: #2f7eed;
    }
    .modal .modal_header h1{
        font-size: 18px;
        line-height: 25px;
    }
    .task_details .task_info .details ul{
        padding: 0;
    }
    .task_details .task_info h4 label {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid var(--var-element-bg-color);
        color: var(--var-element-bg-color);
        font-size: 9px;
        line-height: 18px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        margin: 2px 12px 0 0;
    }
    .task_details .task_info .details p {
        padding: 5px 0 5px 30px;
    }
    .grp_wpr {
        display: flex;
        gap: 8px;
        padding: 10px 0;
    }

    .grp_wpr label .box_item{
        font-size: 13px;
        line-height: 15px;
        border-radius: 4px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0.5;
        transition: all 0.3s ease-in-out;
    }
    .grp_wpr label .box_item i{
        font-size: 15px;
    }
    .grp_wpr label.yes .box_item {
        border: 1px solid #b2eabf;
        background: #effff2;
        color: #23993e;
    }
    .grp_wpr label.no .box_item {
        border: 1px solid #ffb4b4;
        background: #ffecec;
        color: #eb1414;
    }
    .grp_wpr label.active .box_item, .grp_wpr label input[type="radio"]:checked ~ .box_item {
        opacity: 1;
    }
    .task_details .success_goal {
        display: flex;
        align-items: center;
        border-radius: 20px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
        width: 100%;
        padding: 25px 0 25px 30px;
    }
    .task_details .success_goal i{
        color: var(--var-element-bg-color);
        font-size: 12px;
        padding-right:10px;
    }
    .activity_wpr {
        padding: 15px 0 0 30px;
    }
    .activity_wpr_center{
        padding: 15px 0 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
</style>
